import Flatpickr from "stimulus-flatpickr";

// import the translation files and create a translation mapping
import { Greek } from "flatpickr/dist/l10n/gr.js";
import { english } from "flatpickr/dist/l10n/default.js";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  locales = {
    el: Greek,
    en: english
  };

  initialize() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: this.locale,
      monthSelectorType: "static"
    };
  }

  // automatically submit form when a date is selected
  change(selectedDates, dateStr, instance) {}

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return Greek;
    }
  }
}
