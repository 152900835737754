import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "completed" ]

  toggle(event) {
    const userID  = event.target.dataset.id
    const superVisorID  = document.getElementById("supervisor").getAttribute("data-id")
    let formData = new FormData()
    formData.append("supervision[user_id]", userID)
    formData.append("supervision[supervisor_id]", superVisorID)

    if (this.completedTarget.checked) {
      Rails.ajax({
        url: this.data.get("update-url"),
        type: 'post',
        data: formData,
        success: function() {},
        error: function(data) {
          console.log(data)
        }
      })

    } else {
      Rails.ajax({
        url: "/supervisions/:id",
        type: 'delete',
        data: formData,
        success: function() {},
        error: function(data) {
          console.log(data)
        }
      })
    }
  }
}
