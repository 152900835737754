import Flatpickr from "stimulus-flatpickr";

// import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'

// you can also import a translation file
import { Greek } from "flatpickr/dist/l10n/gr.js";
import { english } from "flatpickr/dist/l10n/default.js";

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    var locale = this.element.getAttribute("data-locale");
    locale = locale == "en" ? english : Greek;

    this.config = {
      locale: locale,
      dateFormat: "d-m-Y",
      monthSelectorType: "static"
    };
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    const myData = { start_date: selectedDates[0] };
    const updateChartID = this.inputTarget.getAttribute("data-chart-id");

    if (updateChartID != null) {
      const chart = Chartkick.charts[updateChartID];
      if (chart != null) {
        const locationNodePathName = chart.getDataSource().split("?")[0];
        chart.updateData(
          locationNodePathName + "?" + new URLSearchParams(myData).toString()
        );
      } else {
        console.log(
          "can not find chart with the spacific id: " + updateChartID
        );
      }
    } else {
      Chartkick.eachChart(function(chart) {
        // const path = window.location.protocol + window.location.hostname + chart.getDataSource()
        // const locationNodeURL = new URL(path)
        // const locationNodePathName2 = locationNodeURL.pathname
        //
        const locationNodePathName = chart.getDataSource().split("?")[0];
        chart.updateData(
          locationNodePathName + "?" + new URLSearchParams(myData).toString()
        );
      });
    }
  }
}
