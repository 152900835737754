import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "completed" ]

  toggle(event) {
    const userGroupID  = event.target.dataset.id
    const userID  = document.getElementById("user").getAttribute("data-id")
    let formData = new FormData()
    formData.append("membership[user_id]", userID)
    formData.append("membership[user_group_id]", userGroupID)

    if (this.completedTarget.checked) {
      Rails.ajax({
        url: this.data.get("update-url"),
        type: 'post',
        data: formData,
        success: function() {},
        error: function(data) {
          console.log(data)
        }
      })

    } else {
      Rails.ajax({
        url: "/user-groups/" + userGroupID + "/users/" + userID,
        type: 'delete',
        data: formData,
        success: function() {},
        error: function(data) {
          console.log(data)
        }
      })
    }
  }
}
