import "select2";

export var test = "foobar";

$(document).on("turbolinks:load", function(e) {
  $("select:not(.custom-select)").select2({
    theme: "bootstrap4"
  });
  $('[data-toggle="popover"]').popover();
});
